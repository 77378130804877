import styles from '../styles/header.module.css'
export default function Header() {
 
  return (
    <header className="masthead">
    <div className="container">
        <div className="masthead-subheading">Welcome To Chicago International Medical Training Center</div>
        <div className="masthead-heading text-uppercase">Your Educational Journey Starts Here !</div>
        <a className={`btn ${styles['btn-xl']} ${styles['btn-header']}   text-uppercase`}  href="#services">Tell Me More</a>
    </div>
</header>
  )
}
