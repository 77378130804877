import TeamItem from "./team/TeamItem"
import {Team as TeamObj} from '../model/team'

const teamInfo: TeamObj[] = [
    {
        id:1, 
        name: 'Dr. Amar Hamad, Honorary ',
        title: 'Dr. Hamad is the managing partner of Oncology Associates located in Chicago Ridge Illinois and is part of the McKesson family of practices across the United States.  He serves as Medical Director of the Cancer Institute for Advocate Christ Medical Center located in Oak Lawn, Illinois. Christ is the largest medical facility in the Chicago area. He also serves on a number of other committees as Chairman for oncology related entities.  He is also a distinguished Clinical Assistant Professor for Internal Medicine Section of Hematology/Oncology for the University of Illinois. ', 
        img:'Amar Hamad'
    },
    {
        id:2,
        name: 'Dr. Ghassan Zalzaleh',
        title: 'Dr. Ghassan is a hematologist oncologist working in the Chicago suburb for the last 20 years. I am interested in teaching, and have been on the teaching faculty at Christ Medical Center since 2004. Currently, I am the Hematology Oncology section Chief at Christ Hospital. I have been voted to the best doctors in Chicago list for the last three years, and I believe it is a great honor and a privilege to take care of Oncology patients in my practice, as it shows how much trust they have in me with their very critical disease. ', 
        img: 'Ghassan Zalzaleh'
    },
    {
        id:3,
        name: 'Dr. Khaled El-Zayyat', 
        title: 'Dr. El-Zayyat has more than 25 years of experience in international education and training.  He currently serves as a director at the college of arts and science at Saint Xavier University in Chicago.', 
        img:'Khaled El-Zayyat'
    },
]
export default function Team() {
  return (
    <section className="page-section bg-light" id="team">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Our Amazing Team</h2>
                    <h3 className="section-subheading text-muted">Welcomes You in Chicago</h3>
                </div>
                <div className="row">
                    {teamInfo.map((team:TeamObj)=>(
                        <TeamItem teamInfo={team} key={team.id}/>
                    ))}
                    
                </div>
                <div className="row">
                    {/* <div className="col-lg-8 mx-auto text-center"><p className="large text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut eaque, laboriosam veritatis, quos non quis ad perspiciatis, totam corporis ea, alias ut unde.</p></div> */}
                </div>
            </div>
        </section>
  )
}
