import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';
export default function PagesNaveBar() {
  return (
    
    <Navbar bg="dark" expand="lg" variant="dark" >
        <Container>
      <NavLink to="/" className="navbar-brand text-decoration-none">
              <b>CIMT</b>
      </NavLink>
      
       </Container>
    </Navbar>

  )
}
