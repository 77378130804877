import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from 'react-bootstrap/Card'
import ListGroup from "react-bootstrap/esm/ListGroup";
import {useState } from "react";
import {Service} from "../model/service";
import serviceData from "../data/ServicesData.json" 
import { currencyFormat } from "../utils/Utils";
interface Data{
    cost:number,
    duration: string
}
export default function ServicePage() {
  
const[services, setServices] = useState<Service[]>(serviceData.services);
   
   const outpatientItems = services[0].types.map((obj)=> Object.entries(obj).map(([key, value]: [string, number])=> {
    return  <ListGroup.Item as="li" key={value}>{key} <br/><strong>{currencyFormat(value)}</strong></ListGroup.Item>
   }));
   
   const inpatientItems = services[1].types.map((obj)=> Object.entries(obj).map(([key, value]: [string, number])=> {
    return  <ListGroup.Item as="li" key={value}>{key} <br/><strong>{currencyFormat(value)}</strong></ListGroup.Item>
   }));

   const inoutpatientItems = services[2].types.map((obj)=> Object.entries(obj).map(([key, value]: [string, number])=> {
    return  <ListGroup.Item as="li" key={value}>{key} <br/>sss<strong>{currencyFormat(value)}</strong></ListGroup.Item>
   }));
   

return (
    <>
    <div className="bg-light">
        <h1 className="text-center text-dark p-4">Services</h1>
        <Container className="p-4">
            <Row xs={1} md={2} lg={2} sm={1}>
            <Col>
               <Card>
                  <Card.Img variant="top" src="assets/img/portfolio/familypractice.jpg"/>
                </Card>
            </Col>
            <Col>
                <div className="text-center">
                    <h2>{services[0].title}</h2>
                    <p>
                        {services[0].description}
                    </p>
                </div>
                 <Card body className="bg-custom-blue text-center text-light">
                    <h3>Tution</h3>
                    <ListGroup as='ul'>                        
                     {outpatientItems}
                    </ListGroup>
                 </Card>
                </Col>
            </Row>
           
        </Container>
    </div>
    <div className="bg-custom-blue">
    <Container className="p-4">
        <Row xs={1} md={2} lg={2} sm={1}>
            <Col>
                <div className="p-4 text-center text-light">
                    <h2 className="">{services[1].title}</h2>
                    <p>
                        {services[1].description}
                    </p>
                </div>
                <Card body className="text-center" style={{backgroundColor: `rgb(164,41,49, 0.9)`}}>
                    <h3 className="text-light">Tution</h3>
                    <ListGroup as='ul'>                        
                     {inpatientItems}
                    </ListGroup>
                 </Card>
                </Col>
                <Col className="p-3">
                  <Card>
                    <Card.Img variant="top" src="assets/img/portfolio/generalsurgery.jpg"/>
                  </Card>
                </Col>
              
            </Row>
        </Container>
        
    </div>
    <div className="bg-light">
      <Container className="p-4">
      <Row xs={1} md={2} lg={2} sm={1}>
            <Col>
                  <Card>
                    <Card.Img variant="top" src="assets/img/portfolio/inpatient.jpg"/>
                  </Card>
                </Col>
            <Col>
                <div className="text-center">
                    <h2>{services[2].title}</h2>
                    <p>
                        {services[2].description}
                    </p>
                </div>
                 <Card body className="text-center bg-custom-blue">
                    <h3 className="text-center text-light">Tution</h3>
                    <ListGroup as='ul'>                        
                     {inoutpatientItems}
                    </ListGroup>
                 </Card>
                </Col>
               
              
            </Row>
        </Container>
    </div>
    </>
    
  )
}
