import { Portfolio } from "../../model/portfolio"
interface Props{
    portfolio:Portfolio | null,
    handleOpen: (portfolio: Portfolio)=> void
  }
export default function PortfolioItem({portfolio, handleOpen}: Props) {
 
  return (
    <div className="portfolio-item" onClick={() => handleOpen(portfolio!)}>
      <div className="portfolio-link">
          <div className="portfolio-hover">
          <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
          </div>
          <img className="img-fluid" src={`assets/img/portfolio/${portfolio!.imgUrl}.jpg`} alt={portfolio!.imgUrl}/>
      </div>
      <div className="portfolio-caption">
        <div className="portfolio-caption-heading">{portfolio?.title}</div>
    </div>
    </div>
                            
                      
            
  )
}
