import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Modal from "react-bootstrap/Modal";
import { Portfolio } from "../../model/portfolio";


interface Props{
    show: boolean,
    handleClose: ()=> void,
    portfolioItem: Portfolio
}

export default function PortfolioModal({portfolioItem, show, handleClose}: Props) {
  return (
    <Modal show={show} onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="portfolio-modal">
      <div onClick={handleClose} className="close-modal"><img src="assets/img/close-icon.svg" alt="Close modal" /></div>  
         <Container>
            <Row className="justify-content-center">
               <Col lg={8}>
                  <Modal.Body>
                  <h2 className="text-uppercase">{portfolioItem.title}</h2>
                  {/* <p className="item-intro text-muted">Lorem ipsum dolor sit amet consectetur.</p> */}
                  <img className="img-fluid d-block mx-auto"
                      src={`assets/img/portfolio/${portfolioItem.imgUrl}.jpg`} alt={portfolioItem.id} />
                  <p>{portfolioItem.description}</p>
                  </Modal.Body>
               </Col> 
           </Row>
        </Container>   
        
  </Modal>
  )
}
