import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Footer from "../layout/Footer";
import PagesNaveBar from "../layout/PagesNaveBar";

import ServicePage from "../pages/ServicePage";
export const router = createBrowserRouter([
    { 
        path: "", element: <App/>   
    },
    {
        path:'services',  element: <><PagesNaveBar/> <ServicePage/> <Footer/></>
    },
    
    
    
])