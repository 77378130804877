import React from 'react'
import { Col} from 'react-bootstrap'
import { Team as TeamObj } from '../../model/team'
interface Props {
    teamInfo:  TeamObj
}
export default function TeamItem({teamInfo}:Props) {
  return (
     <Col lg={4}>
                        <div className="team-member">
                            <img className="mx-auto rounded-circle" src={`assets/img/team/${teamInfo.img}.jpg`} alt={teamInfo.title} />
                            <h4>{teamInfo.name}</h4>
                            <p className="text-muted">{teamInfo.title}</p>
                            {/* <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Parveen Anand Twitter Profile"><i className="fab fa-twitter"></i></a> */}
                            {/* <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Parveen Anand Facebook Profile"><i className="fab fa-facebook-f"></i></a> */}
                            {/* <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Parveen Anand LinkedIn Profile"><i className="fab fa-linkedin-in"></i></a> */}
                        </div>
    </Col>

  )
}
