import './App.css';
import Navigation from './layout/Navigation';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Clients from './components/Clients';
import About from './components/About';
import Team from './components/Team';
import Contactus from './components/Contactus';
import Portfolio from './components/Portfolio';
import Services from './components/Services';



function App() {
  
  return (
    <>
       <Navigation/>
       <Header/>
       <Services/>
       {/* <Test/> */}
       <Portfolio/>
       <About/>
       <Team/>
       {/* <Clients/> */}
       <Contactus/>
       <Footer/>             
    </>
  );
}

export default App;
