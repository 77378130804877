import { Service } from '../../model/service'
interface Props{
   service: Service
}
export default function ServiceItem({service}:Props) {
  return (
       <>
       <span className="fa-stack fa-4x">
          <i className="fas fa-circle fa-stack-2x text-custom-red"></i>
          <i className={`${service.font} fa-stack-1x fa-inverse`}></i>
        </span>
        <h4 className="my-3">{service.title}</h4>
        <p className="text-muted">{service.description}</p>
      </>
    
  )
}
