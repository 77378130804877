import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Service } from "../model/service";
import ServiceItem from "./service/ServiceItem"
import { Link } from "react-router-dom";
import serviceData from "../data/ServicesData.json" 
import { useState } from "react";
export default function Services() {  
 const[services] = useState<Service[]>(serviceData.services);
  return (
    <section className="page-section" id="services">
            <Container>
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Services</h2>
                    <h3 className="section-subheading text-muted">Our mission is to provide medical students with the best clinical experience in different setting that will be customized to student’s interest.<br/> Medical trainees will have the choice of three programs:</h3>
                </div>
                <Row className="text-center mb">
                    {services.map(service => (
                        <Col key={service.id} md={4}>
                            <ServiceItem service={service} />
                        </Col>))}
                </Row>
                <h4 className="text-center pb-1">
                    <Link to='services' className="text-custom-red">To Read More About The Programs length And Tuition Fees...</Link></h4>
            </Container>
    </section>
  )
}
