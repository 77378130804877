import { Link } from "react-router-dom"

const about = {
    mainTitle: "CIMT was founded by medical doctors working in the field to provide medical students with the up-to-date training in most medical fields. Chicago international medical training was founded by medical doctors working in the field to provide medical students with the up-to-date training in most medical fields ",
    vision: 'To be a globally recognized leader in providing high-quality clinical training and mentorship for medical students and graduates, fostering their personal and professional growth, and enhancing their ability to provide excellent patient care. Chicago International will provide students with the support they need to succeed through continuous support and guidance to help student choose the right bath for their medical career.',
    mission: 'At CIMT, our mission is to provide comprehensive clinical training programs that equip medical students and graduates from all over the world with the knowledge, skills, and experiences necessary to become outstanding physicians. We strive to create a supportive and inclusive learning environment that values diversity, fosters critical thinking, and encourages lifelong learning. We partner with top medical institutions in the USA to provide access to state-of-the-art facilities and world-class mentors who are dedicated to shaping the next generation of healthcare professionals. Through our rigorous and hands-on training programs, we aim to empower our trainees to make a meaningful impact on global healthcare.',
    info1: "CIMT was founded by medical doctors working in the field to help medical students and graduates from all over the world to get access to professional clinical training at top medical establishments in the USA. Our network of medical professionals and hospitals covers most medical fields and will allow medical students and graduates to choose the right program to fit their field interest.",
    info2:"The program was designed by medical doctors working in the field of medicine with more than 25 years of experience. Students can choose an out-paint program, in-patient program, or in/out patient program.",
}
export default function About() {
    return (
      <section className="page-section" id="about">
              <div className="container">
                  <div className="text-center">
                      <h2 className="section-heading text-uppercase">About</h2>
                      <h3 className="section-subheading text-muted">{about.mainTitle} <Link to='/about'>Read more...</Link></h3>
                      
                  </div>
                  <ul className="timeline">
                      <li>
                          <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/1.jpg" alt="..." /></div>
                          <div className="timeline-panel">
                              <div className="timeline-heading">
                                  {/* <h4>2009-2011</h4> */}
                                  <h4 className="subheading">Our Vision</h4>
                              </div>
                              <div className="timeline-body"><p className="text-muted">{about.vision}</p></div>
                          </div>
                      </li>
                      <li className="timeline-inverted">
                          <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/2.jpg" alt="..." /></div>
                          <div className="timeline-panel">
                              <div className="timeline-heading">
                                  {/* <h4>March 2011</h4> */}
                                  <h4 className="subheading">Our Mission</h4>
                              </div>
                              <div className="timeline-body"><p className="text-muted">{about.mission}</p></div>
                          </div>
                      </li>
                      <li>
                          <div className="timeline-image">
                            <img className="rounded-circle img-fluid" src="assets/img/about/3.jpg" alt="..." /></div>
                          <div className="timeline-panel">
                              <div className="timeline-heading">
                                  <h4>CIMT</h4>
                                  <h4 className="subheading">Chicago International Medical Training Center</h4>
                              </div>
                              <div className="timeline-body"><p className="text-muted">{about.info1}</p></div>
                          </div>
                      </li>
                      <li className="timeline-inverted">
                          <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/4.jpg" alt="..." /></div>
                          <div className="timeline-panel">
                              <div className="timeline-heading">
                                  {/* <h4>July 2020</h4> */}
                                  <h4 className="subheading">Join CIMT</h4>
                              </div>
                              <div className="timeline-body"><p className="text-muted">{about.info2}</p></div>
                          </div>
                      </li>
                      <li className="timeline-inverted">
                          <div className="timeline-image">
                              <h4>
                                  Be Part
                                  <br />
                                  Of Our
                                  <br />
                                  Story!
                              </h4>
                          </div>
                      </li>
                  </ul>
              </div>
          </section>
    )
  }
  